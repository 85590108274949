.main-contentarea {
  min-height: calc(100vh - 190px);
  margin-top: 1.5rem !important;
}
.title-signup {
  font-size: 1.35rem;
  font-weight: bold;
  margin-bottom: 5px;
  color: #172b4d;
  text-align: left;
}
.logincontent {
  background: #172b4d;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 12;
  padding: 2rem 0;
  background-image: url(/../src/assets/img/new/bg.jpg);
  background-size: cover;
  &:before {
    content: "";
    // background: linear-gradient(87deg, #11cdef 0, #1171ef 100%) !important;
    // background-image: url();
    position: absolute;
    top: -89px;
    left: 0;
    right: 0;
    bottom: 48%;
    transform: skew(0deg, 5deg);
  }
  .card-header {
    padding: 0.55rem 1.5rem;
  }
}
.update-password {
  max-width: 500px;
  margin: auto;
}

.navbar-search {
  .input-group {
    border-radius: 12px;
    border: 1px solid;
    border-color: #e9e9e9;
    background: #fff;
    box-shadow: none;
  }
}
.badge-dashboard {
  margin-left: 4px;
  color: #fff;
  font-size: 14px;
  background: rgba(17, 148, 239, 0.5);
  border-radius: 5px;
}
.custom-tabs {
  .nav-tabs {
    border-bottom: 0;
    background: #ebebeb;
    padding: 0.4rem 0.5rem;
    border-radius: 51px;
  }
  .tab-content {
    background: #fff;
    border: 1px solid #dee2e6;
    padding: 1rem 1.5rem;
    border-radius: 10px;
    margin-top: 1rem;
  }
  .nav-tabs {
    .nav-link {
      padding: 0.2rem 0.7rem;
      font-size: 14px;
      border-radius: 51px;
      background-color: transparent;
      box-shadow: none;
      color: rgba(0, 0, 0, 0.9);
      cursor: pointer;
      &.active {
        color: #fff !important;
        background-color: #1194ef !important;
        border-color: transparent;
        .badge-dashboard {
          background: #fff;
          color: #1194ef;
        }
      }
      &:hover {
        border-color: transparent;
      }
    }
  }
}
.navbar-search {
  .input-group {
    flex-wrap: nowrap;
  }
}
.date-group {
  display: flex;
  align-items: center;
  h4 {
    font-size: 14px;
    white-space: nowrap;
    margin: 0.3rem 0.5rem 0.3rem 0;
  }
  .input-group {
    border: 0;
    border-radius: 22px;
  }
  .form-control {
    background: #ffffff;
    border: 0;
    box-shadow: none;
    color: #010101;
    border-radius: 100px;
    color-scheme: dark;
    width: 140px !important;
    height: 30px;
    padding: 0.25rem 0.75rem;
  }
  &.first {
    position: relative;
    margin-right: 25px;
    &::after {
      content: "";
      width: 6px;
      height: 2px;
      background: #959595;
      position: absolute;
      right: -15px;
      bottom: 16px;
    }
  }
  @include media-breakpoint-down(xs) {
    .form-control {
      padding: 0.25rem 0.5rem;
      width: 122px !important;
    }
    &.first {
      margin-right: 5px;
    }
  }
}
.date-boxes {
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  background: #ffffff;
  border-radius: 100px;
  border: solid 1px #efefef;
  box-shadow: 1px 1px 18px 2px rgba(0, 0, 0, 0.02);
  .input-group {
    background: transparent;
  }
  ::-webkit-calendar-picker-indicator {
    filter: invert(1);
    margin-top: 4px;
  }
}
.form-control {
  height: calc(1.5em + 1.25rem + 0px);
}
.card-box {
  margin: 7px 0;
}
.card-stats {
  .card-body {
    padding: 0.5rem 1.5rem;
  }
}
.button-boxes {
  display: flex;
}
.pagination {
  justify-content: flex-end;
  padding: 5px 0;
}
.modal-title {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 17px;
  font-weight: bold;
}
.modal-header {
  .close {
    padding: 1.25rem 0.6rem;
    span {
      font-size: 2rem;
    }
  }
}
.btn-sm {
  padding: 0.25rem 0.75rem;
}
.modal-body {
  padding: 0.5rem 1.5rem;
  p {
    font-size: 14px;
    font-weight: normal;
  }
  label {
    font-size: 14px;
  }
}
.pb-12 {
  padding-bottom: 12rem;
}
.mt--11 {
  margin-top: -11rem !important;
}
.mt--13 {
  margin-top: -13rem !important;
}
@include media-breakpoint-down(xs) {
  .navbar-search {
    .form-control {
      width: 100%;
    }
  }
  .date-group {
    h4 {
      min-width: 40px;
    }
  }
}

.invalid-field {
  display: block !important;
  color: red;
  text-align: left;
}

.resume-invalid {
  display: block !important;
  color: red;
  text-align: center;
}

.switch-wrapper > input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}

.switch-wrapper > input[type="checkbox"] + .switch {
  transform: translateX(5px);
}
.switch-wrapper > input[type="checkbox"]:checked + .switch {
  transform: translateX(50%) translateX(-19px);
}
.switch-wrapper.large > input[type="checkbox"]:checked + .switch {
  transform: translateX(50%) translateX(-35px);
}
.switch-wrapper > input[type="checkbox"]:disabled + .switch {
  background: #ccc;
}

.bg-gradient-second {
  background: linear-gradient(87deg, #6073e4 0, #6477e9 100%) !important;
}
.switch-wrapper {
  border-radius: 20px;
  cursor: pointer;
  height: 22px;
  float: left;
  overflow: hidden;
  position: relative;
  width: 43px;
}
.switch-wrapper.large {
  height: 40px;
  width: 80px;
}
.switch-wrapper > .switch {
  color: #fff;
  display: inline-block;
  height: 100%;
  left: -100%;
  position: relative;
  transition: 100ms linear;
  width: 200%;
}
.switch-wrapper > .switch > .switch-handle {
  background: #fff;
  border-radius: 50%;
  display: inline-block;
  height: 12px;
  left: 53%;
  position: relative;
  top: 3px;
  width: 12px;
  z-index: 3;
}
.switch-wrapper.large > .switch > .switch-handle {
  height: 30px;
  width: 30px;
}
.switch-label {
  float: left;
  line-height: 2.5rem;
  margin-left: 10px;
}
.switch-wrapper.large + .switch-label {
  line-height: 4rem;
  margin-left: 15px;
}
.custom-company {
  position: relative;
  .icons {
    position: absolute;
    left: 13px;
    top: 10px;
    z-index: 12;
    color: #adb5bd;
    font-size: 0.875rem;
  }
  input {
    padding-left: 40px !important;
  }
}

.check-icons {
  position: absolute;
  right: 13px;
  top: 10px;
  z-index: 12;
  color: #adb5bd;
  font-size: 0.875rem;
  cursor: pointer;
}
.check-verify {
  position: absolute;
  right: 13px;
  top: 10px;
  z-index: 12;
  color: blue;
  font-size: 0.875rem;
  cursor: pointer;
}
.cstm-dropdown {
  display: grid;
}
.cstm-iconBtn {
  background: transparent;
  border: 0px;
  box-shadow: none;
  &:hover {
    background: transparent;
    border: 0px;
    box-shadow: none;
  }
  &:focus {
    background: transparent;
    border: 0px;
    box-shadow: none;
  }
  &:active {
    background-color: transparent !important;
    border: 0px;
    box-shadow: none;
  }
}
.cstm-tbl-actions {
  &:hover {
    background: #d8dfe4;
  }
}

.header-cardstats {
  border-radius: 6px !important;
  height: 100%;
  transition: all ease 0.25s;
  background-color: #fff;
  box-shadow: 1px 1px 18px 2px rgba(0, 0, 0, 0.03);
  &.active,
  &:hover {
    border: solid 1px #b4bfff;
    background: #ffffff;
    box-shadow: 1px 1px 18px 2px rgba(94, 114, 228, 0.25);
    .title-sect {
      p {
        color: #5e72e4;
      }
    }
  }
}
.headercard {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  height: 100%;
  align-items: center;
  .title-sect {
    display: flex;
    align-items: center;
    p {
      color: #363636;
      line-height: 21px;
      font-weight: 600;
      font-size: 15px;
    }
  }
  .title-value {
    min-width: 50px;
    text-align: right;
    h2 {
      font-size: 28px;
    }
  }
  .icons {
    width: 34px;
    height: 34px;
    flex: 0 0 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    color: #fff;
    margin-right: 10px;
    img {
      width: 20px;
    }
    &.blue {
      background: #a4dfff;
    }
    &.pink {
      background: #ffc3d1;
    }
    &.green {
      background: #94ffd9;
    }
    &.purple {
      background: #d3c6ff;
    }
  }
}
.custom-searchbar {
  position: relative;
  input {
    color: #fff;
    &::placeholder {
      color: #fff;
    }
  }
  .searchicon {
    position: absolute;
    left: 10px;
    color: #fff;
  }
  &:hover {
    background: #fff;
    input {
      color: #686868;
      &::placeholder {
        color: #686868;
      }
    }
    .searchicon {
      color: #686868;
    }
  }
  &:focus {
    background: #fff;
    input {
      color: #686868;
      &::placeholder {
        color: #686868;
      }
    }
    .searchicon {
      color: #686868;
    }
  }
  &:focus-within {
    background: #fff;
    input {
      color: #686868;
      &::placeholder {
        color: #686868;
      }
    }
    .searchicon {
      color: #686868;
    }
  }
}

.table {
  thead {
    th {
      color: #32325d;
      font-size: 14px;
      font-weight: 700;
      text-transform: none;
      background: #f6f9fc;
      padding-top: 0.85rem;
      padding-bottom: 0.85rem;
      letter-spacing: 0.5px;
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
  }
  tbody {
    td {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
  }
  .table-icons {
    max-height: 20px;
    width: 20px;
  }
  td {
    .btn-sm {
      padding: 0.15rem 0.35rem !important;
      border-radius: 6px !important;
      border: 0;
      box-shadow: none;
      // &:hover {
      //   img {
      //     filter: brightness(0) invert(1);
      //   }
      // }
    }
  }
}
.search-boxes {
  display: flex;
  @include media-breakpoint-down(xs) {
    flex-direction: column;
    .search-custom {
      margin-bottom: 0.5rem;
    }
  }
}
.search-custom {
  // position: absolute;
  //   left: 12px;
  position: relative;
  input[type="text"] {
    width: 230px;
    font-size: 14px;
    -webkit-transition: width 0.4s ease-in-out;
    transition: width 0.4s ease-in-out;
    background-color: transparent;
    border-color: transparent;
    padding-left: 40px;
    color: rgb(53, 53, 53);
    border-radius: 100px;
    border: solid 1px transparent;
    &::placeholder {
      color: #525f7f;
    }
  }
  input[type="text"]:focus {
    width: 300px;
    background-color: #fff;
    color: #525f7f;
    border: solid 1px #e3e3e3;
  }
  &.one {
    z-index: 12;
    margin-right: 10px;
  }

  &.two {
    // left: 220px;
    z-index: 1;
  }
  .icons-search {
    position: absolute;
    left: 8px;
    top: 7px;
    img {
      width: 18px;
    }
    i {
      color: #d3dce1;
    }
  }
}
.dropdown {
  .avatar {
    color: #fff;
    background-color: #1199ef;
    font-weight: bold;
  }
}
.table-height {
  overflow: auto;
  max-height: 600px;
  .table-responsive {
    min-height: 200px;
  }
}
.nodata-table {
  min-height: 275px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    // opacity: 0.2;
    // max-width: 165px;
    opacity: 0.9;
    max-width: 160px;
  }
}

h2.header {
  color: white;
  font-size: 18px;
  font-weight: normal;
}
.navbar-light {
  .navbar-nav {
    .nav-item {
      padding: 0.4rem 0;
    }
    .nav-link {
      color: #787878;
      font-weight: 600;
      transition: all ease 0.25s;
      i {
        color: #3d678d;
      }
      &.active,
      &:hover {
        color: #000;
      }
    }
  }
}
.navbar-vertical {
  &.navbar-expand-md {
    .navbar-brand {
      text-align: center;
      padding: 0 0 20px 0 !important;
      img {
        max-height: 2.3rem;
      }
    }
  }
}
.profile-dropdown {
  overflow: hidden;
  width: 255px;
  border-radius: 6px;
  .dropdown-header {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  button.dropdown-footer {
    background: #5e72e4;
    padding: 0.5rem 1rem;
    margin-top: 10px;
    span {
      font-size: 16px;
    }
    img {
      margin-right: 10px;
      margin-bottom: 3px;
      margin-left: 2px;
    }
  }
  .noti-title {
    h6 {
      text-transform: none;
      font-size: 16px;
      color: #000000;
    }
  }
  .dropdown-item {
    padding-left: 1rem;
    padding-right: 1rem;
    span {
      font-size: 15px;
    }
  }
}
.tab-title {
  font-size: 20px;
  font-weight: bold;
}
.btn {
  padding: 0.525rem 1.25rem;
  border-radius: 12px;
}
.btn-sm {
  padding: 0.25rem 0.75rem !important;
  border-radius: 8px !important;
}
.second-search {
  .date-group {
    .form-control {
      background: rgba(255, 255, 255, 0.3);
    }
  }
}

// customize scrollbar started
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #fafafa;
  border: solid 1px #efefef;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(224, 224, 224);
  border-radius: 10px;
  height: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(214, 214, 214);
}

// customize scrollbar ended
.upload-img {
  aside {
    ul {
      li {
        color: $primary;
        font-size: 12px;
      }
    }
  }
}
.custom-spinner {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1234;
  align-items: center;
  .spinner-round {
    img {
      width: 80px;
    }
  }
}
.react-tel-input {
  .form-control {
    width: 100% !important;
  }
}
.select-positioned {
  position: absolute;
  right: 55px;
  max-width: 300px;
  z-index: 12;
  padding: 0.425rem 1.75rem 0.425rem 0.75rem;
  height: calc(1.5em + 1rem);
}
.add-button-common {
  .add-button {
    display: inline-flex;
    width: 18px;
    height: 18px;
    background: #11a0ef;
    color: #fff;
    padding: 0;
    align-items: center;
    justify-content: center;
    margin-right: 7px;
    border-radius: 50px;
    font-size: 18px;
    font-weight: bold;
  }
  &:hover {
    background: transparent;
    box-shadow: none;
    transform: none;
  }
}

@include media-breakpoint-up(md) {
  .navbar-vertical {
    .navbar-collapse {
      &::before {
        display: none;
      }
    }
  }
}
@media screen and (min-device-width: 992px) and (max-device-width: 1150px) {
  .custom-tabs {
    .col-md-12.col-lg-7 {
      max-width: 100% !important;
    }
    .col-md-12.col-lg-5 {
      max-width: 100%;
    }
  }
  .search-total {
    .col-md-12.col-lg-7 {
      max-width: 100% !important;
    }
    .col-md-12.col-lg-5 {
      max-width: 100%;
    }
  }
}
.up-down-button {
  box-shadow: none;
  padding: 0 4px 3px 4px;
  &:hover {
    box-shadow: none;
  }
  &:focus,
  &:active {
    box-shadow: none !important;
    background: transparent !important;
  }
}

// p {
//   text-transform: capitalize;
// }
.notification {
  position: relative;
  display: inline-block;
  margin-top: 5px;
  .badge {
    position: absolute;
    top: 0px;
    right: 12px;
    border-radius: 100%;
    background: red;
    color: white;
    display: block;
    padding: 2px;
    min-width: 14px;
  }
}
.noti-values {
  padding: 0 5px;
  max-height: 330px;
  overflow: auto;
  .dropdown-item {
    border-radius: 4px;
    border-bottom: solid 1px #efefef;
  }
}
.ntf-color {
  background-color: #edeeef;
}
.nav-header-section {
  z-index: 2;
  display: block;
}

// new styles added
.bg-leftside {
  background-color: #f8f6fa;
}
.bg-rightside {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 0 3rem 0;
  .css-1s2u09g-control {
    border: 1px solid #e9ecef !important;
  }
  .formboxes {
    max-width: 80%;
    flex: 0 0 80%;
    margin: auto;
    // height: calc(100vh - 195px);
    // overflow: auto;
    display: flex;
    justify-content: center;
    .titile-subtag {
      font-size: 13px;
      color: #a7a7a7;
      letter-spacing: 1px;
    }
    // .card-body {
    //   flex: none;
    // }
    @include media-breakpoint-down(sm) {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
  .account-switch {
    position: absolute;
    right: 40px;
    top: 20px;
    a {
      color: #898989;
    }
    .switchbtns {
      border-radius: 100px;
      padding: 0.325rem 1.15rem;
    }
  }
  .custom-company {
    .wrapper {
      border: 1px solid #e9ecef !important;
    }
  }
}
.login-left {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5rem 0 4rem 0;
  height: 100%;
  img {
    width: 100%;
    max-width: 400px;
  }
  .logo-brand {
    position: absolute;
    left: 35px;
    top: 25px;
    img {
      cursor: pointer;
      width: 150px;
    }
  }
  @include media-breakpoint-down(sm) {
    padding: 3rem 0 0rem 0;
    .logo-brand {
      width: 100%;
      left: 0;
      text-align: center;
    }
    img {
      max-height: 173px;
      width: auto;
    }
  }
}
.border-radius-100 {
  border-radius: 100px;
}
.startpage-outer {
  max-width: 980px;
  margin: auto;
}
.searchicon-btn {
  width: 35px !important;
  height: 32px !important;
  padding: 0;
  margin-right: 9px;
  max-width: 35px !important;
  background: transparent;
  border: 0;
  box-shadow: none;
  img {
    width: 18px;
  }
  &:hover,
  &:focus,
  &:active {
    background: transparent;
    box-shadow: none;
    transform: none;
  }
  &:not(:disabled):not(.disabled):active {
    background: transparent;
    box-shadow: none;
    transform: none;
  }
}

.text-to-ellipse {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
}

.eyeicon-btn {
  width: 35px !important;
  height: 20px !important;
  padding: 0;
  margin-right: 9px;
  // max-width: 35px !important;
  background: transparent;
  border: 0;
  box-shadow: none;
  img {
    width: 18px;
  }
  &:hover,
  &:focus,
  &:active {
    background: transparent;
    box-shadow: none;
    transform: none;
  }
  &:not(:disabled):not(.disabled):active {
    background: transparent;
    box-shadow: none;
    transform: none;
  }
}
.upload-img {
  color: #878787 !important;
}
.add-titles-box {
  font-size: 1.2rem;
}

@include media-breakpoint-up(sm) {
  .navbar-expand-md {
    .navbar-nav {
      .nav-link {
        padding-left: 0.2rem;
      }
    }
  }
}

.new-cutom-filter {
  .btn {
    padding: 5px 7px;
    min-width: 50px;
    border: solid 1px #efefef;
    background: #fff;
    border-radius: 100px;
    &::after {
      vertical-align: 0.1em;
      margin-left: 0.355em;
      border-top: 0.3em solid #000;
      display: none;
    }
    &:hover {
      background: #5e72e4;
      img {
        filter: brightness(0) invert(1);
      }
      &::after {
        border-top: 0.3em solid #fff;
      }
    }
    &:focus {
      box-shadow: none;
    }
  }
}
.common-outer-filter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.noti-text {
  white-space: initial;
}
.noti-from {
  display: block;
  text-transform: capitalize;
  margin-bottom: 4px;
}
.noti-date {
  text-align: right;
  display: block;
  color: rgb(146, 146, 146);
  font-size: 11px;
}
.notification-custom {
  min-width: 350px;
}
.btn-status{
  border: none;
    color: white;
    padding: 4px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 10px;
    margin-right: 6px;
    border-radius: 100px;
    cursor: pointer;
    width: 5px;
    height: 5px;
}
.bg-green{
  background-color: rgba(0, 128, 0, 0.905);
}

.bg-yellow{
  background-color: rgb(220, 199, 7);
}

.bg-orange{
  background-color: rgb(193, 126, 11);
}

.circle-red {
  align-items: center;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: red;
  display: inline-block;
}
.circle-green {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: green;
  display: inline-block;
}
.rightside-modal {
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  .modal-content {
    min-height: 100vh;
    border-radius: 0;
  }
  .first-row {
    background: #f5f5f5;
    border-radius: 6px;
    padding: 5px 10px;
    margin-bottom: 6px;
    .values {
      p {
        margin-bottom: 0;
      }
    }
    .labels {
      h4 {
        margin-bottom: 5px;
      }
    }
  }
}
.bookingmodal {
  .first-row {
    background: #f5f5f5;
    border-radius: 6px;
    padding: 3px 10px;
    height: 100%;
    .values {
      p {
        margin-bottom: 0;
      }
    }
    .labels {
      h4 {
        margin-bottom: 5px;
      }
    }
  }
}
.activity-timeline {
  position: relative;
  margin-left: 3px;
  .activityboxes {
    position: relative;
    padding-left: 1.8rem;
    margin-bottom: 10px;
    &::before {
      content: "";
      width: 14px;
      height: 14px;
      border-radius: 100%;
      background: #fff;
      border: solid 3px $primary;
      position: absolute;
      left: 0;
      top: 6px;
    }
    .action-title {
      font-weight: bold;
      font-size: 15px;
      margin-bottom: 3px;
      color: #32325d;
    }
  }
  &::before {
    content: "";
    width: 1px;
    height: calc(100% - 8px);
    background: #a6b3f9;
    position: absolute;
    left: 6px;
    top: 8px;
  }
}

@media (min-width: 768px) {
  .main-content .navbar-top {
    position: fixed;
    margin-left: 250px;
    width: calc(100% - 250px);
  }
}

.form-wizard {
  position: relative;
  z-index: 999;
  width: 34px;
  height: 270px;
  border-radius: 100px;
  padding: 0.3rem 0;
  border: solid 1px #e7e1f9;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-left: -18px;
  .steps {
    border-radius: 100%;
    border: solid 2px #a1a1a4;
    color: #a1a1a4;
    width: 26px;
    height: 26px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    h6 {
      margin-bottom: 0;
    }
    &.working {
      border-color: #5762de;
      color: #5762de;
    }
    &.completed {
      border-color: #5762de;
      color: #fff;
      background: #5762de;
      h6 {
        color: #fff;
      }
    }
    &.failed {
      border-color: #ef2248;
      color: #fff;
      background: #ef2248;
    }
    &:after {
      content: "";
      position: absolute;
      top: 100%;
      height: 53px;
      width: 3px;
      background-image: url(/../src/assets/img/new/dots.png);
      margin-top: 20px;
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
  }
  @include media-breakpoint-down(md) {
    width: 270px;
    height: 34px;
    flex-direction: row;
    margin-left: 1.5rem;
    flex: 0 0 270px;
    .steps {
      &:after {
        top: 9px;
        height: 3px;
        width: 53px;
        margin-top: 0;
        left: 100%;
        margin-left: 1.5rem;
        // transform: rotate(90deg);
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .bg-rightside {
    display: block;
  }
}
.main-content {
  padding-top: 74px;
}

.dashboard-outer-box {
  padding: 1rem 1.5rem;
}

.dashboard-outer-box > div {
  padding: 1.5rem 0;
  border-radius: 12px;
  border: solid 1px #efefef;
}
.cstm-dropdown {
  .cstm-tbl-actions {
    text-align: left;
    padding: 8px 11px;
    display: flex;
    align-items: center;
    box-shadow: none;
    border-radius: 0;
    border: 0;
  }
}

.new-table {
  th {
    height: 52px;
  }
}
.custom-alert {
  max-width: 499px;
  width: 100%;
  padding: 0.85rem 1rem;
  position: fixed;
  right: 15px;
  top: 15px;
  z-index: 12;
  font-weight: 600;
  &.alert-success {
    background: #d1e7dd;
    border: solid 1px #5cbe90;
    color: #0f5132;
  }
  &.alert-danger {
    background: #f8d7da;
    border: solid 1px #e68c94;
    color: #6a1a21;
  }
  .alert-close {
    opacity: 0.5;
  }
}
@include media-breakpoint-down(sm) {
  .form-wizard {
    width: 230px;
    .steps {
      &::after {
        width: 33px;
      }
    }
  }
}

.main-content .navbar-top {
  z-index: 123;
}
.already-account {
  font-weight: 600;
  font-size: 14px;
  margin-top: 10px;
}
.copyright {
  margin: 0 auto;
  // background: #efefef;
  padding: 7px 10px;
  border-radius: 3px;
  font-size: 14px;
}

.table-loader {
  min-height: 275px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    // opacity: 0.2;
    // max-width: 165px;
    opacity: 0.6;
    max-width: 100px;
  }
}
.css-v4u5dn-MuiInputBase-root-MuiInput-root:before {
  border: 0 !important;
}

.css-v4u5dn-MuiInputBase-root-MuiInput-root:hover:not(
    .Mui-disabled,
    .Mui-error
  ):before {
  border: 0 !important;
}
.css-1wuilmg-MuiAutocomplete-root {
  background: #fff;
  border-radius: 100px;
}
// form {
//   background: #fff;
//   border-radius: 100px;
// }

// .chip {
//   display: inline-block;
//   padding: 0 25px;
//   height: 50px;
//   font-size: 16px;
//   line-height: 50px;
//   border-radius: 25px;
//   background-color: #f1f1f1;
// }

.chip {
  /* display: inline-block; */
  padding: 0 10px;
  height: 26px;
  font-size: 12px;
  color: white;
  font-weight: 600;
  line-height: 27px;
  border-radius: 25px;
  background-color: #5e72e4;
}
.closebtn {
  padding-left: 10px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 20px;
  cursor: pointer;
}

.closebtn:hover {
  color: #000;
}
.filter-boxes {

  // max-height: 810px;
  background: #fff;
  padding: 13px;
  border-radius: 4px;
  // height: calc(100% - 75px);
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  .title-area {
    border-bottom: solid 1px #e9e9e9;
    h5 {
      font-size: 18px;
      font-weight: 700;
    }
  }
  .customsearch {
    position: relative;
    .searchicon {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  label {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 0;
  }
  .form-control {
    height: calc(1.5em + 0.857rem);
    border-radius: 4px;
    padding: 0.25rem 0.5rem;
  }
}
.rdrCalendarWrapper {
  width: 100%;
}
.rdrMonth {
  width: 100% !important;
}
.rdrMonthAndYearPickers select {
  padding: 5px 18px 5px 0px !important;
}
.status-indication {
  display: flex;
  p {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 0;
    margin-right: 20px;
  }
}
.rating-area {
  svg.star-svg {
    width: 20px;
}
}
// .style-module_starRatingWrap__q-lJC {
//   display: block !important;
// }
.button-area {
  button {
    padding: 8px 15px !important;
  }
  &.project { 
    margin-bottom: 1.5rem;
  }
}
.skill-values {
  display: flex;
  align-items: center;
  &:last-child {
    margin-bottom: 0;
  }
  span.skillname {
    font-weight: 600;
    font-size: 14px;
    margin-right: 3px;
  }
  .expname {
    background: #fff;
    padding: 1px 8px;
    line-height: 16px;
    border-radius: 50px;
    margin: 2px 6px;
    font-size: 13px;
  }
  .rating-sc {
    background: #fff;
    padding: 3px 6px;
    border-radius: 50px;
    margin: 2px 6px;
    font-size: 14px;
  }
  .rating-area svg.star-svg {
    width: 17px;
}
}
// .custom-toast-container {
//   position: fixed;
//   top: 20px;
//   right: 20px;
//   z-index: 9999;
// }

// .custom-toast-container .Toastify__toast {
//   padding: 0px;
//   border-radius: 0px;
//   font-family: Arial, sans-serif;
// }

// .custom-toast-container .Toastify__toast--success {
//   background-color:transparent !important;
//   color:transparent !important;
// }

// .custom-toast-container .Toastify__toast--error {
//   background-color:transparent !important;
//   color: transparent !important;
// }

// .custom-toast-container .Toastify__toast-body {
//   display: flex;
//   // align-items: center;
// }

// .custom-toast-container .Toastify__toast-body svg {
//   margin-right: 0px;
// }

// .custom-toast-container .Toastify__close-button {
//   margin-left: 0px;
//   color:transparent !important;
// }


.hdr-main {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 1rem 3rem;
  transition: all .3s ease;
}

.skill-header {
  background-color: #0000;
  left: 0;
  position: fixed;
  top: 0;
  transition: all .25s ease;
  width: 100%;
  z-index: 101;
}
.skill-header.sticky .hdr-main{
  padding: 0.7rem 1rem;
}
.sticky {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  background-color: rgb(255, 255, 255);
}
.footer-sc {
  background: #5e72e4;
  padding: 23px 0;
}
.custom-btn{
  background: transparent;
  border: 2px solid white;
  border-radius: 25px;
}


.logincontent {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background-image: url('../../../img/new/bg-light.png');
    background-size: contain;
    width: 70%;
    height: 70%;
    background-repeat: no-repeat;
  }}

  .cstm-footer{
    // padding: 10px;
    // position: absolute;
    margin: auto;
    text-align: center;
    font-weight: 600;
  }
  .footer-wrapper{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 7px;
  }

  .chips-container {
    display: flex;
    // grid-template-columns: repeat(4, 1fr);
    gap: 8px;
    margin-top: 8px;
    flex-wrap: wrap;
  }
  
  .slot-chip {
    margin: 4px 0;
  }

  .time-options {
    display: flex;
    flex-direction: column;
  }
  
  .time-option {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .time-details {
    margin-left: 10px;
  }

  .date-container {
    display: flex;
    flex-direction: column;
  }
  
  .date {
    font-weight: bold;
    margin-bottom: 5px;
  }

  .time{
    font-size: 12px;
  }
